import { Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Loader } from "@mantine/core";

// Middleware
import {
  ProtectedRoute,
  ProtectedTenantRoute,
  RedirectRoute,
} from "./utils/protectedRoute";

import { Home } from "./pages";

//PAGES
const TenantLogin = lazy(() => import("./pages/TenantLogin"));
const TenantDashboard = lazy(() => import("./pages/TenantDashboard"));
const TenantRegister = lazy(() => import("./pages/TenantRegister"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const CreateProperty = lazy(() => import("./pages/CreateProperty"));
const EditProperty = lazy(() => import("./pages/EditProperty"));
const BookingsPage = lazy(() => import("./pages/BookingsPage"));
const ReservationsPage = lazy(() => import("./pages/ReservationsPage"));
const CreateRoom = lazy(() => import("./pages/CreateRoom"));
const FavoritePage = lazy(() => import("./pages/FavoritePage"));
const ListingPage = lazy(
  () => import("./components/propertyListings/ListingPage")
);
const VerifyUserPage = lazy(() => import("./pages/VerifyUserPage"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const EditProfile = lazy(() => import("./pages/EditProfile"));
const EditRoom = lazy(() => import("./pages/EditRoom"));
//END OF PAGES

// MODALS
const AuthModal = lazy(() => import("./components/Modals/AuthModal"));
const TenantRegisterModal = lazy(
  () => import("./components/Modals/TenantRegister")
);
const PropertyDelete = lazy(() => import("./components/Modals/PropertyDelete"));
const RoomDelete = lazy(() => import("./components/Modals/RoomDelete"));
const PaymentModal = lazy(() => import("./components/Modals/PaymentModal"));
const ProofImageModal = lazy(
  () => import("./components/Modals/ProofImageModal")
);
const SearchModal = lazy(() => import("./components/Modals/SearchModal"));
const ReviewModal = lazy(() => import("./components/Modals/ReviewModal"));
// END OF MODALS

function AppComponent() {
  return (
    <main>
      <TenantRegisterModal />
      <AuthModal />
      <PropertyDelete />
      <PaymentModal />
      <ProofImageModal />
      <RoomDelete />
      <SearchModal />
      <ReviewModal />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Home />} />
        <Route path="/sort" element={<Home />} />
        <Route path="/property/:id" element={<ListingPage />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        <Route path="/edit-property/:id" element={<EditProperty />} />
        <Route path="/verify-email" element={<VerifyUserPage />} />

        {/* USER NEEDS TO BE AUTHENTICATED */}
        <Route path="/bookings" element={<ProtectedRoute />}>
          <Route index element={<BookingsPage />} />
        </Route>

        <Route path="/reservations" element={<ProtectedRoute />}>
          <Route index element={<ReservationsPage />} />
        </Route>

        <Route path="/favorites" element={<ProtectedRoute />}>
          <Route index element={<FavoritePage />} />
        </Route>
        <Route path="/edit-profile" element={<ProtectedRoute />}>
          <Route index element={<EditProfile />} />
        </Route>
        {/* END OF USER NEEDS TO BE AUTHENTICATED */}

        {/* PROTECTED TENANT ROUTE */}
        <Route
          path="/tenant/dashboard/edit-property/:id"
          element={<ProtectedTenantRoute />}
        >
          <Route index element={<EditProperty />} />
        </Route>
        <Route
          path="/tenant/dashboard/edit-room/:id"
          element={<ProtectedTenantRoute />}
        >
          <Route index element={<EditRoom />} />
        </Route>

        <Route
          path="/tenant/dashboard/create-property"
          element={<ProtectedTenantRoute />}
        >
          <Route index element={<CreateProperty />} />
        </Route>

        <Route path="/tenant/dashboard" element={<ProtectedTenantRoute />}>
          <Route index element={<TenantDashboard />} />
        </Route>

        <Route
          path="/tenant/dashboard/:propertyId/create-room"
          element={<ProtectedTenantRoute />}
        >
          <Route index element={<CreateRoom />} />
        </Route>
        {/* END OF PROTECTED TENANT ROUTE */}

        {/* IF USER IS AUTHENTICATED IT WILL REDIRECT TO '/' */}
        <Route path="/tenant" element={<RedirectRoute />}>
          <Route index element={<TenantLogin />} />{" "}
        </Route>

        <Route path="/tenant/register" element={<RedirectRoute />}>
          <Route index element={<TenantRegister />} />
        </Route>
        {/* END IF USER IS AUTHENTICATED IT WILL REDIRECT TO '/' */}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <AppComponent />
    </Suspense>
  );
}
